<template>
  <div>
    <b-row>
      <b-col>
        <v-autocomplete
          v-if="_.size(stores) > 0"
          :items="stores"
          :label="$t('STORE')"
          item-text="name"
          item-value="id"
          clearable
          v-model="selectedStoreId"
          v-on:change="doSelectStore"
        ></v-autocomplete>
      </b-col>
      <b-col>
        <v-autocomplete
          v-if="_.size(selectedStoreSuppliers) > 0"
          :items="selectedStoreSuppliers"
          :label="$t('SUPPLIER')"
          item-text="name"
          item-value="id"
          hide-details
          clearable
          v-model="selectedSupplierId"
          v-on:change="doSelectSupplier"
        ></v-autocomplete>
      </b-col>
    </b-row>
    <b-button
      :disabled="selectedStoreId == null || selectedSupplierId == null"
      class="mr-3"
      variant="primary"
      @click="goNextStep()"
      >{{ $t("CONTINUE") }}</b-button
    >
  </div>
</template>
<script>
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";
import { FETCH_STORES_WITH_SUPPLIERS } from "@/core/services/store/shared_store.module";
import { FETCH_ITEMS_BY_STORE_AND_SUPPLIER_RECEIVING } from "@/modules/stock/store/stock.module";

import { mapGetters, mapState } from "vuex";

import _ from "lodash";
export default {
  name: "StepStockActionSelectStoreSupplier",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    doGetItems: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    wizardStep: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      stores: [],
      selectedStoreId: null,
      selectedSupplierId: null
    };
  },
  components: {},
  mounted() {
    let vm = this;
    vm.getSelects();
    vm.getStoresWithSuppliers();
  },
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempItems: {
      get: function() {
        return this.items;
      },
      set: function(newValue) {
        this.$emit("update:items", newValue);
      }
    },
    tempDoGetItems: {
      get: function() {
        return this.doGetItems;
      },
      set: function(newValue) {
        this.$emit("update:doGetItems", newValue);
      }
    },
    selectedStore() {
      let vm = this;
      return _.find(vm.stores, { id: vm.selectedStoreId });
    },
    selectedStoreSuppliers() {
      let vm = this;
      return _.size(vm.selectedStore) > 0 ? vm.selectedStore.suppliers : [];
    },
    selectedSupplier() {
      let vm = this;
      return _.find(vm.selectedStore.suppliers, { id: vm.selectedSupplierId });
    },
    storesParams() {
      let storesParams = {};
      return storesParams;
    },
    itemsParams() {
      let vm = this;
      let itemsParams = {
        storeId: vm.selectedStoreId,
        supplierId: vm.selectedSupplierId
      };
      return itemsParams;
    }
  },
  watch: {
    storesParams(value) {
      let vm = this;
    }
  },
  methods: {
    getSelects() {
      let vm = this;
      let payload = {};
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.tempFirstLoader = false;
        })
        .catch(response => {
          console.log(response);
        });
    },
    getStoresWithSuppliers() {
      let vm = this;
      let params = this.storesParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_STORES_WITH_SUPPLIERS, apiParams)
        .then(data => {
          this.$set(vm, "stores", data.data);
          this.$set(vm, "selectedStoreId", null);
          this.$set(vm, "selectedSupplierId", null);
        })
        .catch(response => {
          console.log(response);
        });
    },
    getItems() {
      let vm = this;
      let params = this.itemsParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_ITEMS_BY_STORE_AND_SUPPLIER_RECEIVING, apiParams)
        .then(data => {
          vm.tempItems = data.data;
          vm.tempDoGetItems = false; //prevent reload same items if selectStore is not changed
          this.updateWizardStep(2);
        })
        .catch(response => {
          console.log(response);
        });
    },
    doSelectStore() {
      let vm = this;
      vm.selectedSupplierId = null;
      this.$emit("update:selectedStore", vm.selectedStore);
      this.$emit("update:selectedSupplier", {});
    },
    doSelectSupplier() {
      let vm = this;
      vm.tempDoGetItems = true;
      this.$emit("update:selectedSupplier", vm.selectedSupplier);
    },
    goNextStep() {
      let vm = this;
      if (vm.tempDoGetItems == true) {
        this.getItems();
      } else {
        this.updateWizardStep(2);
      }
    },
    updateWizardStep(wizardStep) {
      this.$emit("update:wizardStep", wizardStep);
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    handleInput(prop, value) {
      this.$emit("stepperInput", {
        prop: prop,
        value: value
      });
    }
  }
};
</script>
